import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
} from 'react-router-dom';
import logo from 'staticFiles/opti-mix-logo.png';

export default function ErrorPage() {
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh',
    }}
    >
      <img
        src={logo}
        style={{ width: 120, height: 'auto' }}
        alt="LAW logo"
      />
      <p>{t('nothing_here')}</p>
      <Button
        variant="contained"
        component={Link}
        to="/"
      >
        {t('home')}
      </Button>
    </Box>
  );
}
