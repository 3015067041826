/* eslint-disable guard-for-in */
import React from 'react';
import { useFunctions } from 'reactfire';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { isVoid, removeEmpty } from './utils/objectManipulation';
import { checkConstraintsBounds, checkFinalVolume } from './utils/validation';

export default function Optimize(props) {
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const optimizeCall = httpsCallable(functions, 'optimixcall');
  const [globalState, globalActions] = useGlobal();

  const {
    products,
    properties,
    productsProperties,
    productConstraints,
    propertyConstraints,
    finalVolume,
    setOptimizationResult,
    addResult,
  } = props;
  const [error, setError] = React.useState('');

  React.useEffect(() => { setError(''); }, [productsProperties, productConstraints, propertyConstraints]);

  const validInput = () => {
    if (error !== '') {
      return false;
    }
    if (properties.length === 0) {
      setError('add Properties');
      return false;
    }
    if (products.length === 0) {
      setError('add Products');
      return false;
    }
    const faultyPropertyId = checkConstraintsBounds(propertyConstraints);
    if (faultyPropertyId !== null) {
      setError(`${properties[faultyPropertyId].name} max value is below its min value`);
      return false;
    }
    const faultyProductId = checkConstraintsBounds(productConstraints);
    if (faultyProductId !== null) {
      setError(`${products[faultyProductId].name} max volume to use is below its min volume to use`);
      return false;
    }
    const finalVolumeError = checkFinalVolume(productConstraints, finalVolume);
    if (finalVolumeError !== null) {
      setError(finalVolumeError);
      return false;
    }

    // eslint-disable-next-line guard-for-in
    for (const productId in products) {
      if (!(productId in productsProperties)) {
        setError(`Enter ${products[productId].name} properties`);
        return false;
      }
      for (const propertyId in properties) {
        if (!(propertyId in productsProperties[productId]) || isVoid(productsProperties[productId][propertyId])) {
          setError(`Enter ${products[productId].name} ${properties[propertyId].name}`);
          return false;
        }
      }
    }
    return true;
  };

  const APIConstraints = (constraints) => (
    Object.keys(constraints).map((constraintId) => (
      { ...removeEmpty(constraints[constraintId]), ...{ name: constraintId } }
    ))
  );

  const APIProductsProperties = (table) => (
    Object.keys(table).map((productId) => ({
      name: productId,
      properties: Object.keys(table[productId]).map((propertyId) => ({
        name: propertyId,
        value: table[productId][propertyId],
      })),
    }))
  );

  const postData = {
    constraints: APIConstraints(propertyConstraints),
    volume_constraints: APIConstraints(productConstraints),
    products: APIProductsProperties(productsProperties),
  };

  if (finalVolume !== '') {
    postData.final_volume = finalVolume;
  }

  const handleClick = async () => {
    const hasRequiredGrants = globalState.accessGrants.some(
      (grant) => grant.app === 'optimix'
        && grant.name === 'law-xo',
    );
    if (!hasRequiredGrants) {
      globalActions.setSnackbarMessage({ message: t('no_access_grants'), severity: 'info' });
      return;
    }
    setOptimizationResult({ status: 'pending' });
    try {
      console.log(JSON.stringify(postData));
      const result = await optimizeCall(postData);
      addResult({ ...result.data, status: 'success' });
    } catch (err) {
      setOptimizationResult({ status: 'error', error: err });
    }
  };

  return (
    <Tooltip title={error}>
      <span>
        <Button
          size="large"
          onClick={handleClick}
          variant="contained"
          disabled={!validInput()}
          sx={{ my: 3 }}
        >
          {t('main_page.solutions.optimize')}
        </Button>
      </span>
    </Tooltip>
  );
}
