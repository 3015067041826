import React from 'react';
import {
  Box,
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material';
import Title from '../Title';

export default function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    fields,
    titles = [],
  } = props;

  const headCells = fields.map((field, i) => ({
    id: field,
    numeric: field !== 'name',
    disablePadding: i === 0,
    label: titles.at(i) || field,
  })).filter((cell) => cell.id !== 'id');

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Title content={headCell.label} />
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ display: 'none' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
