/* eslint-disable guard-for-in */
import * as XLSX from 'xlsx';

function getValue(value) {
  console.log(value);
  return value !== undefined && value !== null ? value : '';
}

function createXlsxFromJson(data) {
  // Create an array to hold the sheet data
  const sheetData = [];

  const propertyNamesRow = ['Produits', 'Volume min', 'Volume cible', 'Volume max', 'Solution vol', 'Solution %'];
  const propertyNames = Object.values(data.properties).map((property) => property.name);
  sheetData.push(propertyNamesRow.concat(propertyNames));

  // Objectives rows (assumes objectives are in a specific order in data.propertyConstraints)
  const lowerBounds = ['Objectif min', '', '', '', '', ''];
  const targets = ['Objectif cible', '', data.finalVolume || '', '', '', ''];
  const upperBounds = ['Objectif max', '', '', '', '', ''];

  for (const propertyId in data.propertyConstraints) {
    const constraints = data.propertyConstraints[propertyId];
    lowerBounds.push(constraints.lower_bound);
    targets.push(constraints.target);
    upperBounds.push(constraints.upper_bound);
  }

  sheetData.push(lowerBounds);
  sheetData.push(targets);
  sheetData.push(upperBounds);

  // "SOLUTION" row
  const solutionRow = new Array(6).fill('');
  solutionRow[0] = 'Solution';
  Object.keys(data.properties).forEach((propertyId, index) => {
    solutionRow[6 + index] = getValue(data.result?.mixture_properties?.[propertyId]);
  });
  sheetData.push(solutionRow);

  // Product rows
  for (const productId in data.products) {
    const product = data.products[productId];
    const productProperties = data.productsProperties[productId];
    const productConstraints = data.productConstraints[productId];

    const row = [
      product.name,
      '',
      '',
      '',
      getValue(data.result?.volumes?.[productId]),
      getValue(data.result?.proportions?.[productId]),
    ];

    propertyNames.forEach((propertyName) => {
      const propertyId = Object.keys(data.properties).find((key) => data.properties[key].name === propertyName);
      row.push(getValue(productProperties[propertyId]));
    });

    row[3] = getValue(productConstraints.upper_bound);

    sheetData.push(row);
  }

  // Create a worksheet and workbook
  const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  return workbook;
}

export default createXlsxFromJson;
