import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSigninCheck, useUser, useFirestore } from 'reactfire';
import useGlobal from 'global-state/store';
import {
  BrowserRouter, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAnalytics } from 'firebase/analytics';
import {
  addDoc, collection, doc, getDoc,
} from 'firebase/firestore';
import ScrollToTop from 'components/ScrollToTop';
import GlobalSnackbar from 'components/GlobalSnackbar';
import TopButtons from 'components/navBar/TopButtons';
import Footer from 'components/Footer';
import { Contact, Cookies } from 'labox-ws-commons';
import privacyFR from 'staticFiles/privacy-policy-fr.pdf';
import privacyEN from 'staticFiles/privacy-policy-en.pdf';
import Optimization from 'components/Optimization';
import PDFView from 'components/PDFView';
import cgu from 'staticFiles/CGU.pdf';
import cgv from 'staticFiles/CGV.pdf';
import cgucgv from 'staticFiles/CGU+CGV.pdf';
import LoadingPage from 'components/LoadingPage';
import { MenuProvider } from 'menu-actions/MenuContext';
import { getAccessGrants } from 'components/utils';
import SignInPage from './SignInPage';
import MyAccount from './account/MyAccount';
import ErrorPage from './ErrorPage';

export default function Welcome() {
  const { status } = useSigninCheck();
  const { data: user } = useUser();
  const db = useFirestore();
  const [, globalActions] = useGlobal();

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const getUserPrivateData = useCallback(async (uid) => {
    const userRef = doc(db, 'users', uid, 'private', 'data');
    const firestoreUser = await getDoc(userRef);
    return firestoreUser;
  }, [db]);

  const fetchMultipleTimesUserPrivateData = useCallback(async (uid) => {
    let firestoreUser = await getUserPrivateData(uid);
    if (!firestoreUser.exists()) {
      await sleep(1000);
      firestoreUser = await getUserPrivateData(uid);
      if (!firestoreUser.exists()) {
        await sleep(3000);
        firestoreUser = await getUserPrivateData(uid);
      }
    }
    return firestoreUser;
  }, [getUserPrivateData]);

  const getAccessGrantsCb = useCallback(async (activeOrganization) => getAccessGrants(
    db,
    user,
    activeOrganization,
  ), [db, user]);

  useEffect(() => {
    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');

      const firestoreUserPrivateData = await fetchMultipleTimesUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      if (userPrivateData) {
        globalActions.setActiveOrganization(userPrivateData.activeOrganization);
        const userOrganizations = userPrivateData.organizations.reduce((obj, id) => ({
          ...obj,
          [id]: undefined,
        }), {});
        globalActions.setUserOrganizations(userOrganizations);
        const accessGrants = await getAccessGrantsCb(userPrivateData.activeOrganization);
        globalActions.setAccessGrants(accessGrants.validGrants);
        globalActions.setAccessGrantNames(accessGrants.uniqueGrantNames);
      }
      return globalActions.setUserStatus('setupDone');
    }

    if (user?.uid !== undefined) {
      setupGlobalStateFromUser();
    }
  }, [fetchMultipleTimesUserPrivateData, getAccessGrantsCb, globalActions, user?.uid]);

  if (status === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return <MenuProvider app={<MainPage />} />;
}

function MainPage() {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          minHeight: '80vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <GlobalSnackbar />
          <TopButtons />
          <MainPageContent />
        </Box>
        <Footer />
        <Cookies
          cookieName="agreed-to-cookies-on-labox-optimix"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function MainPageContent(props) {
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!signInCheckResult?.signedIn === true
      && location.pathname !== '/contact'
      && location.pathname !== '/cgu'
      && location.pathname !== '/cgv'
      && location.pathname !== '/cgu-cgv'
      && location.pathname !== '/privacy-policy') {
      navigate('/signin');
    }
  }, [location.pathname, navigate, signInCheckResult?.signedIn]);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Optimix, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  const privacyPolicy = () => {
    if (t('locale') === 'fr') {
      return privacyFR;
    }
    return privacyEN;
  };

  if (globalState.userStatus !== 'setupDone') {
    return (
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route exact path="account" element={<MyAccount />} />
        <Route exact path="signin" element={<SignInPage />} />
        <Route
          path="contact"
          element={(
            <Contact
              t={t}
              navigate={navigate}
              globalActions={globalActions}
              analytics={analytics}
              sendEmail={sendEmail}
              signInCheckResult={signInCheckResult}
            />
          )}
        />
        <Route path="cgu" element={<PDFView path={cgu} />} />
        <Route path="cgv" element={<PDFView path={cgv} />} />
        <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
        <Route path="cgu-cgv" element={<PDFView path={cgucgv} />} />
        <Route
          path="*"
          element={(
            <ErrorPage />
          )}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Optimization />} />
      <Route exact path="account" element={<MyAccount />} />
      <Route exact path="signin" element={<SignInPage />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
        )}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="cgu-cgv" element={<PDFView path={cgucgv} />} />
      <Route
        path="*"
        element={(
          <ErrorPage />
        )}
      />
    </Routes>
  );
}
